import React from 'react';
import styles from './footer-links.module.scss';

type FooterLinkProps = {
  children: any;
};

export const FooterLinkContainer: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return (
      <div className="container">
        <div className={styles.footer_link_container}>{children}</div>
      </div>
    );
  };

export const FooterLinkTitle: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return <div className={styles.footer_link_title}>{children}</div>;
  };

export const FooterLinkHeading: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return <div className={styles.footer_link_heading}>{children}</div>;
  };

export const FooterLinkSubHeading: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return <div className={styles.footer_link_sub_heading}>{children}</div>;
  };

export const FooterLinkContent: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return <div className={styles.footer_link_content}>{children}</div>;
  };

export const FooterLinkContentList: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return <ul className={styles.footer_link_content_list}>{children}</ul>;
  };

export const FooterLinkContentBlankItem: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return (
      <li className={styles.footer_link_content_blank_item}>{children}</li>
    );
  };

export const FooterLinkContentBulletItem: React.FunctionComponent<FooterLinkProps> =
  props => {
    const { children } = props;
    return (
      <li className={styles.footer_link_content_bullet_item}>{children}</li>
    );
  };
